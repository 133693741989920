@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px
}

body {
  font-family: "Poppins", sans-serif;
}

/* background color blur effect */
.container2 {
  position: relative;
  overflow: visible;
}

.container2::before {
  content: '';
  background-color: #e0f7e9;
  filter: blur(95px);
  position: absolute;
  border-radius: 50%;
  top: -35%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: -1;
}

.lg\:col-span-4.col-span-12 {
  position: relative;
}

/* carousel style */
.react-multi-carousel-dot button {
  background-color: #cdd1ce !important;
  border-color: #cdd1ce !important;
}

.react-multi-carousel-dot--active button {
  background-color: #00923F !important;
  border-color: #00923F !important;
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
  width: 350px;
}

.react-multi-carousel-dot-list {
  justify-content: center !important;
  bottom: -5px !important;
  left: 2% !important;
}