.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* src/GradientText.css */
.gradient-text {
  background: linear-gradient(to right, #fff, #EE7424);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-otp-field {
  @apply mx-2 h-10 !w-10 border rounded-md border-gray-400;
}

.custom-otp-field:first-child {
  @apply mr-1 ml-0;
}

@media (max-width: 768px) {
  .custom-otp-field {
    width: 32px !important;
    height: 32px !important;
    margin: 0 5px;
  }
}